<template>
	<div>
		<b-modal
			id="modal-account-statement-total-deposit"
			cancel-variant="outline-secondary"
			ok-only
			ok-title="Ok"
			centered
			size="xl"
			:title="total.des"
		>
			<b-card
				v-if="accountStatementTotalDepositDetail"
				no-body
				class="border"
			>
				<b-table
					ref="refAccountStatementTotalDeposit"
					class="position-relative table-white-space mb-0"
					:items="accountStatementTotalDepositDetail"
					responsive
					:fields="tableColumns"
					primary-key="index"
					:sort-by.sync="sortBy"
					show-empty
					empty-text="No matching records found"
					:sort-desc.sync="isSortDirDesc"
				>
					<template #cell(index)="data">
						{{ perPage * (currentPage - 1) + data.index + 1 }}
					</template>

					<!-- Column: User -->
					<template #cell(username)="data">
						{{ data.item.user.username }}
					</template>

					<template #cell(paymentType)="data">
						n/a
					</template>

					<template #cell(currency)="data">
						VND
					</template>

					<template #cell(netAmount)="data">
						{{ data.item.netAmount ? numberFormat(data.item.netAmount) : 'n/a' }}
					</template>

					<template #cell(amount)="data">
						<span class="text-primary">{{ numberFormat(data.item.amount) }}</span>
					</template>

					<!-- Column: Status -->
					<template #cell(status)="data">
						<div :class="`text-capitalize align-items-center d-flex text-${resolveStatus(data.item.stat).variant}`">
							<span :class="`dot bg-${resolveStatus(data.item.stat).variant}`" />{{ resolveStatus(data.item.stat).label }}
						</div>
					</template>

					<template #cell(createdAt)="data">
						{{ formatDateTime(data.item.created_at) }}
					</template>

				</b-table>
				<div class="p-2">
					<b-row>

						<b-col
							cols="12"
							sm="6"
							class="d-flex align-items-center justify-content-center justify-content-sm-start"
						>
							<span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
						</b-col>
						<!-- Pagination -->
						<b-col
							cols="12"
							sm="6"
							class="d-flex align-items-center justify-content-center justify-content-sm-end"
						>

							<b-pagination
								v-model="currentPage"
								:total-rows="totalaccountStatementTotalDepositDetail"
								:per-page="perPage"
								first-number
								last-number
								class="mb-0 mt-1 mt-sm-0"
								prev-class="prev-item"
								next-class="next-item"
							>
								<template #prev-text>
									<feather-icon
										icon="ChevronLeftIcon"
										size="18"
									/>
								</template>
								<template #next-text>
									<feather-icon
										icon="ChevronRightIcon"
										size="18"
									/>
								</template>
							</b-pagination>

						</b-col>

					</b-row>
				</div>
			</b-card>
		</b-modal>
	</div>
</template>

<script>
import {
	BButton, BModal, VBModal,
	BCard, BRow, BCol, BFormInput, BTable, BTr, BTh, BLink,
	BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import { numberFormat, formatDateTime } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import depositStoreModule from '@/views/payments/deposit/depositStoreModule'
import store from '@/store'
import router from '@/router'

export default {
	components: {
		BButton,
		BModal,
		BCard,
		BRow,
		BCol,
		BFormInput,
		BTable,
		BTr,
		BTh,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
		vSelect,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	props: {
		total: {
			type: Object,
			required: true,
		},
		fromDate: {
			type: String,
			required: true,
		},
		toDate: {
			type: String,
			required: true,
		},
	},
	setup(props) {
		const toast = useToast()
		const perPage = ref(20)
		const totalaccountStatementTotalDepositDetail = ref(0)
		const currentPage = ref(1)
		const perPageOptions = [10, 25, 50, 100]
		const sortBy = ref('id')
		const isSortDirDesc = ref(true)
		const fromdateFilter = ref(null)
		const todateFilter = ref(null)

		const refAccountStatementTotalDeposit = ref(null)

		if (!store.hasModule('payment-deposit')) store.registerModule('payment-deposit', depositStoreModule)

		const tableColumns = [
			'index',
			{ key: 'createdAt', label: 'Created At' },
			{ key: 'username' },
			{ key: 'paymentType', label: 'Payment Type' },
			{ key: 'ref', label: 'Transaction Id' },
			'currency',
			{ key: 'amount', label: 'Gross Amount' },
			{ key: 'netAmount', label: 'Net Amount' },
			'status',
		]

		const refetchData = () => {
			refAccountStatementTotalDeposit.value.refresh()
		}

		watch([currentPage, perPage, fromdateFilter, todateFilter], () => {
			refetchData()
		})

		const accountStatementTotalDepositDetail = (ctx, callback) => {
			store
				.dispatch('payment-deposit/fetchDeposits', {
					perPage: perPage.value,
					page: currentPage.value,
					sortBy: sortBy.value,
					sortDesc: isSortDirDesc.value,
					userId: router.currentRoute.params.id,
					fromdate: props.fromDate,
					todate: props.toDate,
				})
				.then(response => {

					const { deposits, total, pageSize } = response

					callback(deposits)
					totalaccountStatementTotalDepositDetail.value = total
					perPage.value = pageSize
				})
				.catch(() => {
					toast({
						component: ToastificationContent,
						props: {
							title: 'Error fetching deposits list',
							icon: 'AlertTriangleIcon',
							variant: 'danger',
						},
					})
				})
		}

		const dataMeta = computed(() => {
			const localItemsCount = refAccountStatementTotalDeposit.value ? refAccountStatementTotalDeposit.value.localItems.length : 0
			return {
				from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
				to: perPage.value * (currentPage.value - 1) + localItemsCount,
				of: totalaccountStatementTotalDepositDetail.value,
			}
		})

		const resolveStatus = status => {
			if (status === 1) return { label: 'init', variant: 'warning' }
			if (status === 2) return { label: 'success', variant: 'success' }
			return { label: 'none', variant: 'secondary' }
		}

		return {
			tableColumns,
			perPage,
			currentPage,
			totalaccountStatementTotalDepositDetail,
			dataMeta,
			perPageOptions,
			sortBy,
			isSortDirDesc,
			refAccountStatementTotalDeposit,

			accountStatementTotalDepositDetail,

			resolveStatus,

			numberFormat,
			formatDateTime,
		}
	},
}
</script>